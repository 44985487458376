import React, { useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import HeroSplit from "../components/Hero/HeroSplit";
import Services from "../components/Repeating/Services";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import WhyUs from "../components/Repeating/WhyUs";
import CTABackgroundImage from "../components/CTA/CTABackgroundImage";
import ModalGallery from "../components/Modal/ModalGallery";
import IndustriesSidebar from "../components/Repeating/IndustriesSidebar";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
   const [slideIndex, setSlideIndex] = useState(0);

   return (
      <Layout headerStyle="overlap" headerLinkColor="white" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Fiberglass Shelter Manufacturer | Pop-Up Shelter"
            description="Rhino Shelters are durable, waterproof, easy to clean and sanitize, and designed for longevity. Created by Painted Rhino, a top fiberglass shelter manufacturer."
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroSplit
            hasContainer={false}
            backgroundImageWrapperWidth="1/2"
            textWrapperWidth="1/2"
            backgroundImages={data.heroDesktop.childImageSharp.fluid}
            backgroundImageSide="right"
            backgroundSizeDesktop="cover"
            backgroundSizeMobile="cover"
            backgroundPositionDesktop="50% 50%"
            backgroundPositionMobile="50% 50%"
            minHeightDesktop="704px"
            minHeightMobile="330px"
         >
            <h1>Fiberglass Shelter Manufacturer</h1>
            <p className="font-semibold text-3xl leading-[30.8px] text-[#444] mb-5 -mt-2">
               Prices starting at $6,500
            </p>
            <p>
               Our prefabricated fiberglass shelters are durable, waterproof, easy to clean and sanitize, and designed for quick assembly and disassembly. The applications are wide-ranging—from a pop-up shelter for those who are temporarily unhoused to emergency disaster shelters, job sites, and more.
            </p>
            <ButtonSolid as="button" data="modal-contact" text="Get a Quote" className="w-full md:w-auto" />
         </HeroSplit>

         <section className="pb-18 md:pb-36">
            <div className="container">
               <div className="grid gap-y-12 md:grid-cols-12 md:gap-x-10 lg:gap-x-20">
                  <div className="md:col-span-8 md:col-start-1">
                     <div className="mb-12 md:mb-16">
                        <h2>The Benefits of Fiberglass Construction</h2>
                        <p>
                           Unlike wooden shelters, which can easily degrade over time, or metal structures, which can rust, fiberglass shelters
                           provide strength and longevity. And when you order from Painted Rhino, you get these additional features and benefits:
                        </p>
                        <ul className="styled-list">
                           <li>Easy to build and move. Bolt assembly for quick build and tear downs.</li>
                           <li>
                              Fast assembly. Stock design typically takes 2 individuals one hour to build. Each shelter is shipped flat on a pallet
                              with all instructions included.
                           </li>
                           <li>Expandable. Create longer structures by adding additional roof panels and walls.</li>
                           <li>
                              Optional flooring. Choose a floor with a drain, or purchase without flooring for use on concrete pads, cement, or
                              trailers.
                           </li>
                           <li>Waterproof. Keeps the contents dry and provides protection from wet weather.</li>
                           <li>
                              COVID protection. The fiberglass material can be fully bleached and sanitized between each use, which is especially
                              important for creating safe temporary prefab shelters.
                           </li>
                        </ul>
                     </div>

                     <h2>Applications</h2>
                     <p>Our <a href="https://www.paintedrhino.com/fiberglass-composite-parts-manufacturing/" className="text-[#006ee8]">premium fiberglass</a> shelters are multi-purpose and can be used in many useful and impactful ways.</p>

                     <h3 className="heading-five">Temporary Prefabricated Shelters</h3>
                     <p>
                        Pop-up shelters can provide much-needed shelter during inclement weather or provide those who are temporarily unhoused with temporary cover while permanent housing is sought.
                     </p>

                     <h3 className="heading-five">Emergency Shelters</h3>
                     <p>
                        The easy-up structures can provide much-needed shelter after natural disasters such as hurricanes, flooding, fires, or
                        earthquakes.
                     </p>

                     <h3 className="heading-five">Job Sites</h3>
                     <p>Keep your equipment covered, house your conduit and electrical, or utilize the shelter as a job site tool locker.</p>

                     <h3 className="heading-five">Other Applications</h3>
                     <p>
                        Our fiberglass shelters can be used for any number of other purposes, including a mobile office, security guard shack, shed,
                        or even a tiny home.
                     </p>
                  </div>
                  <div className="md:col-end-13 lg:col-span-4">
                     <IndustriesSidebar />
                  </div>
               </div>
            </div>
         </section>

         <section className="pb-20 md:pb-32">
            <div className="container">
               <div className="grid gap-2 md:grid-cols-2 md:gap-4">
                  <div className="grid grid-cols-10 grid-rows-2 gap-2 md:gap-4">
                     <div className="col-span-6 col-start-1 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(0)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail1.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-4 col-end-11 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(1)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail2.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-4 col-start-1 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(4)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail5.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-6 col-end-11 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(3)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail4.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                  </div>
                  <div className="grid grid-flow-col grid-cols-10 grid-rows-2 gap-2 md:gap-4">
                     <div className="col-span-4 row-span-2">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(2)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail3.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(5)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail6.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(6)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail7.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <WhyUs className="pb-20 md:pb-28" contentAlignment="md:text-center md:mx-auto" headerHeading="The Painted Rhino Difference." />

         <TestimonialBackgroundImage />

         <Services
            className="mb-22 pt-20 md:mb-32 md:pt-32"
            headerHeading="Our Manufacturing Processes"
            headerText="At Painted Rhino, you bring us your design and we’ll manufacture it. We’re experts in:"
         />

         <CTABackgroundImage />

         <ModalGallery slideIndex={slideIndex}>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox1.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox2.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox3.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox4.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox5.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox6.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox7.childImageSharp.fluid} alt="" />
            </div>
         </ModalGallery>
      </Layout>
   );
};

export default Page;

export const query = graphql`
   {
      heroDesktop: file(relativePath: { eq: "industries/Shelters/Hero.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 800) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      galleryThumbnail1: file(relativePath: { eq: "industries/Shelters/Gallery-Thumbnails/1.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 355) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox1: file(relativePath: { eq: "industries/Shelters/Gallery-Lightbox/1.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail2: file(relativePath: { eq: "industries/Shelters/Gallery-Thumbnails/2.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 221) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox2: file(relativePath: { eq: "industries/Shelters/Gallery-Lightbox/2.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail3: file(relativePath: { eq: "industries/Shelters/Gallery-Thumbnails/3.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 222) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox3: file(relativePath: { eq: "industries/Shelters/Gallery-Lightbox/3.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail4: file(relativePath: { eq: "industries/Shelters/Gallery-Thumbnails/4.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox4: file(relativePath: { eq: "industries/Shelters/Gallery-Lightbox/4.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail5: file(relativePath: { eq: "industries/Shelters/Gallery-Thumbnails/5.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 222) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox5: file(relativePath: { eq: "industries/Shelters/Gallery-Lightbox/5.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail6: file(relativePath: { eq: "industries/Shelters/Gallery-Thumbnails/6.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox6: file(relativePath: { eq: "industries/Shelters/Gallery-Lightbox/6.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail7: file(relativePath: { eq: "industries/Shelters/Gallery-Thumbnails/7.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox7: file(relativePath: { eq: "industries/Shelters/Gallery-Lightbox/7.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
   }
`;
